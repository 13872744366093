import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 843.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,843.000000) scale(0.100000,-0.100000)">
					<path d="M6310 7926 c-56 -19 -128 -74 -157 -117 -22 -34 -249 -515 -338 -719
-18 -41 -51 -113 -73 -160 -22 -47 -77 -166 -122 -265 -76 -166 -264 -574
-340 -740 -18 -38 -61 -132 -95 -208 l-63 -137 1081 2 1082 3 57 28 c61 30
105 74 140 140 19 35 23 57 23 137 l-1 95 -207 450 c-115 248 -230 497 -257
555 -289 631 -366 796 -390 829 -29 40 -101 89 -159 109 -44 15 -136 15 -181
-2z"/>
					<path d="M5091 5209 c-53 -10 -139 -65 -177 -112 -32 -40 -309 -640 -326 -704
-15 -63 -4 -155 27 -218 28 -59 72 -102 138 -137 l42 -23 1599 -3 1599 -2 51
23 c29 13 68 39 89 56 83 74 118 220 79 331 -29 81 -353 784 -366 792 -16 11
-2702 8 -2755 -3z"/>
					<path d="M6580 3563 c-69 -26 -65 -126 5 -133 55 -6 95 21 95 64 0 46 -57 85
-100 69z"/>
					<path d="M4366 3511 c-6 -9 -109 -302 -191 -543 l-43 -128 78 0 78 0 23 75 22
76 130 -3 129 -3 22 -70 22 -70 78 -3 c71 -3 77 -1 72 15 -3 10 -55 162 -115
338 l-109 320 -95 3 c-52 1 -98 -1 -101 -7z m139 -256 c20 -64 38 -122 42
-130 4 -12 -10 -15 -81 -15 -47 0 -86 3 -86 6 0 8 72 237 77 247 2 4 6 7 8 7
2 0 20 -52 40 -115z"/>
					<path d="M4860 3180 l0 -340 70 0 70 0 0 36 0 36 29 -31 c34 -37 69 -51 122
-51 77 0 143 42 182 115 18 34 22 57 22 145 0 92 -3 111 -25 154 -33 65 -85
107 -144 113 -69 8 -112 -4 -151 -43 l-35 -35 0 121 0 120 -70 0 -70 0 0 -340z
m290 55 c86 -44 85 -237 -2 -284 -20 -11 -35 -13 -64 -6 -55 15 -78 51 -82
135 -5 84 9 125 52 151 38 23 58 24 96 4z"/>
					<path d="M6000 3180 l0 -340 75 0 75 0 0 81 c0 81 11 120 33 118 7 0 41 -45
76 -100 l65 -99 84 0 c64 0 81 3 75 12 -4 7 -47 71 -95 142 -48 71 -88 135
-88 142 0 6 7 18 16 25 20 16 164 187 164 194 0 3 -36 5 -79 5 l-80 0 -76 -95
c-42 -52 -81 -97 -86 -100 -5 -4 -9 66 -9 174 l0 181 -75 0 -75 0 0 -340z"/>
					<path d="M7120 3393 l0 -128 -26 33 c-14 18 -39 39 -54 47 -60 31 -177 9 -222
-41 -68 -77 -86 -250 -36 -356 34 -72 86 -110 164 -116 64 -5 105 9 139 49 13
16 26 29 29 29 3 0 6 -16 6 -35 l0 -35 70 0 70 0 0 340 0 340 -70 0 -70 0 0
-127z m-47 -170 c64 -57 61 -203 -6 -259 -52 -44 -123 -23 -151 46 -33 83 -10
200 46 228 30 15 85 8 111 -15z"/>
					<path d="M7920 3421 l0 -60 -32 -3 c-32 -3 -33 -4 -36 -55 l-3 -52 33 -3 33
-3 5 -165 c6 -187 10 -198 78 -231 33 -17 56 -20 102 -17 33 2 62 6 64 9 3 3
0 27 -7 55 -10 38 -16 48 -30 46 -10 -2 -30 4 -43 13 -24 16 -25 17 -22 153
l3 137 48 3 48 3 -3 52 -3 52 -47 3 -48 3 0 60 0 59 -70 0 -70 0 0 -59z"/>
					<path d="M5554 3337 c-91 -44 -134 -123 -134 -244 0 -167 93 -263 251 -263 79
0 126 17 175 62 56 52 79 111 79 208 0 58 -5 86 -23 122 -47 95 -102 130 -217
135 -69 4 -87 1 -131 -20z m175 -103 c32 -23 51 -74 51 -138 0 -103 -37 -156
-107 -156 -33 0 -50 6 -68 24 -65 65 -53 240 19 275 32 15 79 13 105 -5z"/>
					<path d="M6537 3354 c-4 -4 -7 -121 -7 -261 l0 -253 75 0 75 0 -2 258 -3 257
-65 3 c-36 2 -69 0 -73 -4z"/>
					<path d="M7460 3342 c-43 -17 -110 -87 -110 -117 0 -14 43 -25 96 -25 23 0 37
6 44 19 12 24 72 45 102 36 35 -11 58 -36 58 -62 0 -29 -19 -37 -128 -52 -139
-18 -192 -64 -192 -164 0 -87 68 -147 167 -147 63 0 99 13 136 48 l27 26 0
-32 0 -32 65 0 65 0 0 198 c0 235 -5 251 -90 295 -44 22 -66 27 -124 26 -42 0
-90 -7 -116 -17z m190 -312 c0 -29 -7 -48 -28 -71 -24 -26 -34 -31 -77 -31
-115 0 -106 112 11 131 93 16 94 16 94 -29z"/>
					<path d="M8358 3350 c-44 -13 -83 -40 -106 -74 -30 -45 -28 -62 9 -70 61 -12
89 -7 113 21 18 21 32 26 69 27 48 1 70 -12 81 -49 10 -35 -8 -46 -109 -61
-152 -23 -198 -53 -211 -140 -8 -59 26 -130 76 -156 71 -37 202 -15 233 38 12
22 25 8 19 -21 -4 -25 -4 -25 67 -25 l71 0 0 190 c0 138 -4 200 -14 223 -17
42 -66 83 -114 97 -43 12 -145 12 -184 0z m169 -328 c-7 -62 -41 -92 -104 -92
-53 0 -83 21 -83 57 0 32 32 61 77 68 21 4 52 10 68 15 42 10 48 4 42 -48z"/>
					<path d="M5982 398 c-27 -27 -3 -66 32 -52 22 9 27 32 10 49 -19 18 -26 19
-42 3z"/>
					<path d="M4956 248 c-27 -79 -51 -149 -53 -155 -4 -9 6 -13 29 -13 31 0 36 4
44 33 9 31 12 32 66 35 l56 3 12 -36 c10 -31 15 -35 46 -35 29 0 34 3 29 18
-3 9 -27 78 -53 152 l-47 135 -40 3 -40 3 -49 -143z m104 21 c6 -22 14 -47 17
-55 4 -11 -4 -14 -32 -14 -36 0 -37 1 -32 28 7 35 25 82 31 82 3 0 10 -18 16
-41z"/>
					<path d="M5220 235 l0 -155 30 0 c21 0 30 5 30 17 0 15 1 15 18 0 19 -17 67
-22 97 -11 25 10 45 58 45 109 0 51 -20 99 -45 109 -30 11 -78 6 -97 -11 -17
-15 -18 -14 -18 40 0 57 0 57 -30 57 l-30 0 0 -155z m138 16 c8 -4 16 -26 19
-48 4 -33 1 -45 -16 -62 -26 -26 -36 -26 -61 -1 -24 24 -27 82 -4 104 17 18
42 20 62 7z"/>
					<path d="M5730 235 l0 -155 29 0 c27 0 30 3 33 37 4 50 22 52 54 4 22 -35 32
-41 60 -41 19 0 34 3 34 6 0 3 -19 32 -41 64 l-41 59 41 48 41 48 -33 3 c-27
3 -37 -3 -67 -37 -20 -23 -39 -41 -43 -41 -4 0 -7 36 -7 80 l0 80 -30 0 -30 0
0 -155z"/>
					<path d="M6230 333 c0 -54 -1 -55 -18 -40 -22 20 -80 22 -104 3 -52 -39 -51
-163 1 -203 23 -18 81 -16 103 4 17 15 18 15 18 0 0 -12 9 -17 30 -17 l30 0 0
155 0 155 -30 0 c-30 0 -30 0 -30 -57z m-10 -92 c5 -11 10 -36 10 -55 0 -44
-36 -74 -69 -57 -28 16 -38 74 -17 106 20 30 61 33 76 6z"/>
					<path d="M6590 345 c0 -14 -7 -28 -15 -31 -8 -4 -15 -17 -15 -30 0 -15 6 -24
15 -24 12 0 15 -15 15 -74 0 -88 12 -106 72 -106 32 0 39 3 34 16 -3 9 -6 20
-6 25 0 5 -9 9 -20 9 -18 0 -20 7 -20 64 0 59 2 65 23 68 14 2 22 10 22 23 0
13 -8 21 -22 23 -18 3 -23 10 -23 33 0 26 -3 29 -30 29 -25 0 -30 -4 -30 -25z"/>
					<path d="M5540 311 c-82 -23 -94 -183 -15 -219 106 -48 203 43 161 152 -24 62
-76 86 -146 67z m70 -61 c30 -30 27 -95 -4 -115 -30 -20 -51 -11 -66 26 -14
32 -7 70 16 93 20 21 30 20 54 -4z"/>
					<path d="M6384 306 c-35 -16 -51 -45 -32 -58 19 -12 34 -10 48 7 15 18 42 19
58 3 23 -23 12 -36 -35 -43 -68 -9 -93 -28 -93 -69 0 -19 8 -40 18 -49 21 -19
73 -22 103 -7 13 7 19 7 19 0 0 -5 14 -10 30 -10 l30 0 0 91 c0 103 -7 123
-51 138 -41 14 -57 13 -95 -3z m86 -144 c0 -25 -19 -42 -47 -42 -28 0 -40 24
-23 45 18 22 70 20 70 -3z"/>
					<path d="M6774 306 c-35 -16 -51 -45 -32 -58 19 -12 34 -10 48 7 16 19 44 19
60 0 17 -21 5 -32 -43 -40 -63 -10 -87 -29 -87 -69 0 -19 8 -40 18 -49 21 -19
73 -22 103 -7 13 7 19 7 19 0 0 -5 14 -10 30 -10 l30 0 0 91 c0 103 -7 123
-51 138 -41 14 -57 13 -95 -3z m86 -145 c0 -41 -71 -57 -78 -17 -5 23 14 36
51 36 20 0 27 -5 27 -19z"/>
					<path d="M7135 306 c-41 -18 -70 -81 -62 -133 9 -57 54 -93 116 -93 43 0 53 4
75 31 14 17 26 35 26 40 0 15 -57 10 -70 -6 -15 -18 -51 -20 -68 -3 -16 16
-15 93 1 105 27 22 48 22 64 3 15 -18 73 -29 73 -15 0 23 -35 65 -61 74 -40
14 -57 13 -94 -3z"/>
					<path d="M7374 306 c-41 -18 -68 -75 -61 -130 18 -129 206 -129 224 0 7 56
-24 118 -68 133 -41 14 -57 13 -95 -3z m78 -53 c12 -11 18 -30 18 -59 0 -36
-5 -47 -25 -60 -24 -16 -27 -16 -45 1 -25 23 -27 91 -3 117 20 22 31 23 55 1z"/>
					<path d="M7660 303 c-17 -12 -25 -13 -28 -5 -2 7 -17 12 -33 12 l-29 0 0 -116
0 -115 33 3 32 3 2 75 c3 94 6 102 40 98 28 -3 28 -3 31 -90 l3 -88 29 0 30 0
0 79 c0 83 11 106 47 99 15 -3 19 -17 23 -88 l5 -85 34 -3 33 -3 -4 99 c-4
109 -12 127 -60 137 -22 5 -37 2 -57 -13 -26 -19 -27 -19 -50 -1 -29 23 -50
24 -81 2z"/>
					<path d="M5970 195 l0 -115 30 0 30 0 0 115 0 115 -30 0 -30 0 0 -115z" />
					<path d="M6974 125 c-10 -25 4 -45 30 -45 27 0 42 26 25 46 -15 19 -48 18 -55
-1z"/>
					<path d="M11848 33 c-21 -2 -38 -9 -38 -14 0 -6 -4 -8 -8 -5 -5 3 -22 1 -38
-4 -19 -6 30 -8 149 -8 97 1 176 3 174 4 -6 7 -162 34 -182 32 -11 -1 -37 -3
-57 -5z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
